<template>
  <v-col cols="12" sm="6">
    <v-card class="px-4 mt-6" elevation="4" style="border: 1px solid #dbdade">
      <v-card-title class="align-start">
        <v-radio-group v-model="conditionType" row dense>
          <v-radio label="Include" value="include"></v-radio>
          <v-radio label="Exclude" value="exclude"></v-radio>
        </v-radio-group>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          small
          outlined
          dense
          fab
          elevation="4"
          @click.native="deleteSelf"
          right
          >x</v-btn
        >
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            outlined
            label="Type de dimension"
            v-model="dimensionType"
            :items="dimensionTypes"
            dense
            deletable-chips
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="8">
          <v-autocomplete
            outlined
            label="Valeur"
            v-model="dimension"
            :items="dimensions"
            dense
            multiple
            deletable-chips
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import axios from "@axios";

export default {
  name: "DimensionInline",
  props: {
    partnerId: {
      type: Number,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    },
    existingFilter: {
      type: Object,
      required: false,
      default: null,
    },
  },
  async created() {
    this.dimensionTypes = await this.getDimensionTypes(this.partnerId);
    if (this.existingFilter) {
      this.conditionType =
        "exclude" in this.existingFilter ? "exclude" : "include";
      this.dimensionTypeStr = this.existingFilter.name;
      const dim =
        this.dimensionTypes.filter((obj) =>
          obj.name.includes(this.dimensionTypeStr)
        )[0] || null;
      this.dimensionType = dim.id;
      this.dimension = this.existingFilter[this.conditionType];
    }
  },
  data() {
    return {
      conditionType: "include",
      dimensionType: null,
      dimensionTypeStr: "",
      dimensionTypes: [],
      dimension: null,
      dimensions: [],
    };
  },
  methods: {
    deleteSelf() {
      this.$emit("deleteDimension", this.uuid);
    },
    async getDimensionTypes(partnerId) {
      const {
        data: { items },
      } = await axios.get("/partner-dimension-types/", {
        params: { partner_id: partnerId },
      });

      return items;
    },
    async getDimensions(dimensionTypeId) {
      const {
        data: { items },
      } = await axios.get("/partner-dimensions/", {
        params: { dimension_type_id: dimensionTypeId, limit: 9999 },
      });
      return items;
    },
    updateDimensionData() {
      if (
        this.dimension &&
        this.dimensionType &&
        this.dimensionTypeStr.length > 0
      ) {
        this.$emit("updateDimension", {
          uuid: this.uuid,
          conditionType: this.conditionType,
          dimensionType: this.dimensionTypeStr,
          dimension: this.dimension,
        });
      }
    },
  },
  watch: {
    conditionType() {
      this.updateDimensionData();
    },
    async dimensionType(n, _o) {
      this.dimensions = await this.getDimensions(n);
      this.dimensionTypeStr = this.dimensionTypes.filter((x) => x.id === n)[0][
        "name"
      ];
      if (!this.existingFilter) this.dimension = null;
    },
    dimension() {
      this.updateDimensionData();
    },
  },
};
</script>

<style scoped></style>
