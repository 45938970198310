<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        editing
          ? `Editer la Vue de Partenaire : ${partnerView.label}`
          : "Créer une nouvelle Vue de Partenaire"
      }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="partnerViewForm">
            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  outlined
                  v-model="partnerView.label"
                  dense
                  label="Nom"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  outlined
                  v-model="partnerView.identifier"
                  dense
                  label="Identifiant"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="partnerView.partnerId"
                  :items="$store.getters['premium/getPartnersList']"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Partner"
                  :rules="[valueSelected]"
                  :error-messages="partnerErrors"
                  dense
                  :disabled="disableEditPartner"
                  deletable-chips
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-tooltip top v-if="disableEditPartner">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on">
                      {{ icons.mdiMessageQuestionOutline }}
                    </v-icon>
                  </template>
                  <span
                    >Il faut supprimer les filtres avant de pouvoir changer le
                    partenaire.</span
                  >
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="partnerView.tableName"
                  :items="tableNames"
                  label="Nom de la table"
                  :rules="[required]"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="partnerView.coeff"
                  outlined
                  dense
                  :rules="[required]"
                  label="Coefficient"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row ref="dimensionsContainer"> </v-row>
            <v-row>
              <v-col cols="12" sm="3">
                <v-btn
                  color="primary"
                  class="mt-4"
                  outlined
                  @click="addNewDimension"
                >
                  Ajouter un filtre
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8">
                <v-textarea
                  v-model="generatedFilters"
                  outlined
                  dense
                  label="Filtres Générés"
                  disabled
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="8">
                <v-select
                  outlined
                  dense
                  v-model="partnerView.natureId"
                  :items="natureList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Nature"
                  item-text="name"
                  item-value="id"
                  :rules="[dropdownSelected]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="savePartnerView"
                >
                  Enregistrer la Vue de Partenaire</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'admin-list-partner-view' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {
  required,
  integerValidator,
  valueSelected,
} from "@core/utils/validation";
import { mdiMessageQuestionOutline } from "@mdi/js";
import axios from "@axios";
import router from "@/router";
import DimensionInline from "@/views/pages/admin/partner-view/components/DimensionInline";
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "PartnerViewCreateOrUpdate",
  components: { DimensionInline },
  async created() {
    this.partnersList = await this.$store.dispatch("premium/getPartnersList");
    await this.$store.dispatch("admin/getPartnerNatureList");

    this.tableNames = await this.getTableNames();
    if ("id" in this.$route.params && this.$route.params.id) {
      await this.getPartnerViewById(this.$route.params.id);
      this.editing = true;
      this.partnerView.pk = this.$route.params.id;
    } else {
      this.editing = false;
    }
  },
  data() {
    return {
      required,
      dropdownSelected: (v) => {
        return !!v;
      },
      valueSelected,
      integerValidator,
      editing: false,
      generatedFilters: "[]",
      partnersList: [],
      tableNames: [],
      disableEditPartner: false,
      filters: [],
      partnerErrors: [],
      partnerView: {
        pk: -1,
        label: "",
        partnerId: -1,
        tableName: "",
        dimension_types: "",
        coeff: "1.0",
        natureId: -1,
        identifier: "",
      },
      icons: {
        mdiMessageQuestionOutline,
      },
    };
  },
  methods: {
    async savePartnerView() {
      const isFormValid = this.$refs.partnerViewForm.validate();
      if (!isFormValid) return;
      const payload = {
        label: this.partnerView.label,
        partner_id: this.partnerView.partnerId,
        table_name: this.partnerView.tableName,
        dimension_types: this.generatedFilters,
        coeff: this.partnerView.coeff,
        nature_id: this.partnerView.natureId,
        identifier: this.partnerView.identifier,
      };

      if (this.editing) {
        const { status } = await axios.put(
          `/partner-views/${this.partnerView.pk}`,
          payload
        );
        if (status === 200) {
          await router.push({ name: "admin-list-partner-view" });
        }
      } else {
        const { status } = await axios.post("/partner-views/", payload);
        if (status === 200) {
          await router.push({ name: "admin-list-partner-view" });
        }
      }
    },
    addNewDimension(existingFilter = null) {
      const isFormValid = this.$refs.partnerViewForm.validate();
      if (!existingFilter) {
        if (!isFormValid) return;
      }

      const uuid = uuidv4();
      const filter = { uuid };
      this.filters.push(filter);
      const partnerId = this.partnerView.partnerId;
      const DimensionInlineClass = Vue.extend(DimensionInline);
      const instance = new DimensionInlineClass({
        parent: this,
        propsData: {
          partnerId,
          uuid,
          existingFilter,
        },
      });
      instance.$mount();
      instance.$on(["deleteDimension"], (uuid) => {
        this.filters = this.filters.filter((x) => x.uuid !== uuid);
        instance.$destroy();
        instance.$el.parentNode.removeChild(instance.$el);
      });
      instance.$on(["updateDimension"], this.updateDimension);
      this.$refs.dimensionsContainer.appendChild(instance.$el);
    },
    updateDimension(e) {
      this.filters = this.filters.map((x) =>
        x["uuid"] === e["uuid"] ? { ...e } : x
      );
    },
    async getPartnerViewById(id) {
      const { status, data } = await axios.get(`/partner-views/${id}`);
      if (status === 200) {
        this.partnerView = {
          ...data,
          partnerId: data.partner_id,
          tableName: data.table_name,
          coeff: data.coeff + "",
          natureId: data.nature_id,
        };
        let existingFilters = JSON.parse(data.dimension_types);
        if (existingFilters.length) {
          for (let existingFilter of existingFilters) {
            this.addNewDimension(existingFilter);
          }
        }
        this.generatedFilters = data.dimension_types;
      }
    },
    async getTableNames() {
      const { data } = await axios.get(`/partner-views/tables/`);
      return data;
    },
    generateFilters() {
      const items = [];
      for (const x of this.filters) {
        const conditionType = x["conditionType"];
        const filterItem = {
          name: x["dimensionType"],
          [conditionType]: x["dimension"],
        };
        items.push(filterItem);
      }
      this.generatedFilters = JSON.stringify(items);
    },
  },
  computed: {
    natureList() {
      return this.$store.getters["admin/getPartnerNatureList"];
    },
  },
  watch: {
    filters(n) {
      this.disableEditPartner = n.length > 0;
      this.generateFilters();
    },
  },
};
</script>

<style scoped></style>
